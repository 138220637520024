class BannerAds {
    constructor() {
        /* Document load */
        document.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }
    init() {
        // Banner ads on site - Load the JS and run the functions once loaded
        const adBannerScript = document.createElement('script');
        adBannerScript.src = 'https://acscdn.com/script/aclib.js';
        adBannerScript.onload = function() {
            const adAttr = 'data-aclib';
            const adEls = document.querySelectorAll(`[${adAttr}]`);

            adEls.forEach(el => {
                const adData = el.getAttribute(adAttr); // Retrieve the value of data-aclib

                const script = document.createElement('script');
                // Inject the adData directly into aclib.runBanner()
                script.innerHTML = `aclib.runBanner(${adData});`;
                el.appendChild(script); // Append the script to the element
            });
        };
        document.head.appendChild(adBannerScript);
    }
}

export default new BannerAds();
