// Project imports
import env from '@js/utils/env';

class Navigation {
    constructor() {
        /* Platform nav button */
        let envDevice = 'browser';
        if (env.isAndroid) {
            envDevice = 'android';
        }
        else if(env.isIOS) {
            envDevice = 'ios';
        }

        const platformBtns = document.querySelectorAll(`[data-nav-platform-btn="${envDevice}"]`);
        platformBtns.forEach(btn => {
            btn.classList.remove('hidden');
            btn.classList.add('block');
        });
    }
}

export default new Navigation();
