/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// App imports
// import htmx from 'htmx.org';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import Glide from '@glidejs/glide';
import 'lazysizes';
import mediumZoom from 'medium-zoom/dist/pure';
import reframe from 'reframe.js';

// Project imports
// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import Calcs from '@js/helpers/Calcs';

// Scrolling modules
import PageScrolling from '@js/modules/PageScrolling';
import PageScrollingManager from '@js/modules/PageScrollingManager';

// Project modules
import BannerAds from '@js/modules/BannerAds';
import Navigation from '@js/modules/Navigation';
import SideAdsScroll from '@js/modules/SideAdsScroll';

// Alpine
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.start();

// HTML element classlist
const HTML_CLASSLIST = document.documentElement.classList;

window.APP =
    window.APP ||
    new (function APP() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            GoToScrollPos: false,
            ModalOpenClass: '_modal-open',
            ScrollYPos: 0,
            PreviousUrl: null,
        };

        this.modules = {
            PageScrolling: PageScrolling,
            PageScrollingManager: PageScrollingManager,
        };

        /* Document load */
        document.addEventListener('DOMContentLoaded', () => {
            // Reframe
            reframe('.reframe');

            // Image zoom
            mediumZoom('.zoomable', {
                background: 'rgba(31,41,55,.95)',
                margin: 40,
            });

            // Carousels
            const carouselEls = document.querySelectorAll('.carousel');
            carouselEls.forEach(carousel => {
                const glide = new Glide(carousel, {
                    type: 'carousel',
                    perView: 1,
                });
                glide.mount();
            });

            // Data links - Used to avoid ad blockers
            const dataLinkAttr = 'data-xyz';
            const dataLinkEls = document.querySelectorAll(`[${dataLinkAttr}]`);
            dataLinkEls.forEach(el => {
                el.addEventListener('click', (event) => {
                    event.preventDefault(); // Prevent default behavior if needed

                    const url = el.getAttribute(dataLinkAttr);
                    if (url) {
                        // Open URL in a new tab
                        window.open(url, '_blank', 'noopener,noreferrer');
                    }
                });
            });
        });
    })();
