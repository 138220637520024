class Calcs {
	constructor() {
        this.calulate();

        document.addEventListener('DOMContentLoaded', e => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('load', () => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        const header = document.querySelector('.mn-hdr');
        const footer = document.querySelector('.mn-ftr');

        /* View height */
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh.toFixed(2)}px`);

        /* Header height */
        if (header) {
            const headerRect = header.getBoundingClientRect();
            // Get the header element height
            let hh = headerRect.top + headerRect.height;
            // Then we set the value in the --hh custom property to the root of the document
            document.documentElement.style.setProperty('--hh', `${hh}px`);
        }

        /* Footer height */
        if (footer) {
            const footerStyles = window.getComputedStyle ? getComputedStyle(footer, null) : footer.currentStyle;
            const footerMarginTop = parseInt(footerStyles.marginTop) || 0;

            // Get the header element height
            let fh = footer.offsetHeight + footerMarginTop;
            // Then we set the value in the --hh custom property to the root of the document
            document.documentElement.style.setProperty('--fh', `${fh}px`);
        }
    }
}

export default new Calcs();
