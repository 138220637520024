class SideAdsScroll {
    constructor() {
        this.adBanners = document.querySelectorAll('.side-ad');
        this.footer = document.querySelector('.mn-ftr');
        this.adsShowEl = document.querySelector('.side-ads-show');
        this.headerHeight = document.querySelector('.mn-hdr').offsetHeight + 56;
        this.showAdsScrollPosition = this.adsShowEl ? (this.adsShowEl.offsetTop - this.headerHeight)  : 540;

        this.footerRect = null;
        this.footerMarginTop = null;

        this.handleScroll();

        // Update footer information on resize
        window.addEventListener('resize', () => {
            this.handleScroll();
        });
        // Add scroll event listener
        window.addEventListener('scroll', () => {
            this.handleScroll();
        });
    }
    updateFooterInfo() {
        this.footerRect = this.footer.getBoundingClientRect();
        this.footerMarginTop = parseInt(getComputedStyle(this.footer).marginTop, 10) || 0;
    }
    handleScroll() {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;

        this.updateFooterInfo();

        this.adBanners.forEach(ad => {
            ad.style.top = this.footerMarginTop;

            // Show ads when scrolling past `showAdsScrollPosition`
            if (scrollY > this.showAdsScrollPosition) {
                ad.classList.add('show');
            } else {
                ad.classList.remove('show');
            }

            // Stop ads at the footer
            if ((this.footerRect.top + this.headerHeight) <= windowHeight) {
                ad.style.position = 'absolute';
                ad.style.top = `${this.footer.offsetTop - ad.offsetHeight - this.footerMarginTop}px`;
            } else {
                ad.style.position = 'fixed';
                ad.style.top = `${this.headerHeight}px`;
            }
        });
    }
}

export default new SideAdsScroll();
